//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {required} from "vuelidate/lib/validators";
export default {
  name: "RequestNewServiceForm",

  emits: ["onBackToList", 'onCloseModal'],

  data() {
    return {
      isLoading: false,

      currentCountry: "",

      form: {
        "Адрес сайта": "",
        "Текст СМС": "",
        "Цена номера": "",
        email: "",
      },
    };
  },
  validations() {
    let form = {
      "Адрес сайта": {},
      "Текст СМС": {},
    };

    form["Адрес сайта"] = {
      required: Boolean(this.form["Текст СМС"]) ? false : required,
    };

    form["Текст СМС"] = {
      required: Boolean(this.form["Адрес сайта"]) ? false : required,
    };

    return {
      form,
    };
  },

  props: {
    countrysList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    isDisable() {
      const form = this.form;
      return this._isEmptyObject(form);
    },
    isRecaptchaTest() {
      return Boolean(Number.parseFloat(this.$store?.state?.appState?.config?.useReCaptchaForForms || 0))
    }
  },

  components: {
    CustomMultiselect: () => import("~/components/common/CustomMultiselect.vue")
  },

  methods: {
    onBackToList() {
      this.$emit("onBackToList");
    },

    async onSubmitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return null;
      }

      this.isLoading = true;
      const newForm = {...this.form};

      let body = {};
      body.type = "Запрос на добавление сервиса";

      if (this.isRecaptchaTest) {
        body['g-recaptcha-response'] = await this.$recaptcha.getResponse().then((res) => {
          return res
        }).catch(() => {
          return ""
        });
        await this.$recaptcha.reset();
      }

      let message = Object.keys({...newForm}).map((key) => {
        return `${key}: ${newForm[key] || "[не заполнено]"}`;
      });
      message.push(
        `Желаемая страна: ${this?.currentCountry?.name || "[не заполнено]"}`
      );
      body.message = message.join("<br />");

      body.nophone = 1;

      const res = await this.$api.agent.transport
        .post("form/send", body)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return {error: err.response};
        });

      this.$toast.success("Успешно отправлено");
      this.form = {
        "Адрес сайта": "",
        "Текст СМС": "",
        "Цена номера": "",
        email: "",
      };
      this.$v.$reset();
      this.isLoading = false;

      this.$emit('onCloseModal')
    },

    _isEmptyObject(obj) {
      const res = [];

      Object.entries(obj).forEach(([key, value]) => {
        if (value) {
          res.push(value);
        }
      });

      return res.length ? true : false;
    },
  },
};
